import { createStore } from "vuex";

export default createStore({
  state: {
    lastPos: 0,
    back: null,
    isLoading: false,
    options: {
      extra: {
        data: [],
      },
    },
    food: {
      burgers: {
        data: [],
      },
      sandwiches: {
        data: [],
      },
      bruschette: {
        data: [],
      },
      snacks: {
        data: [],
      },
      plates: {
        data: [],
      },
    },
    beer_styles: [],
    beer_categories: [],
    beer_breweries: [],
    drink: {
      beers: {
        data: [],
      },
      cocktails: {
        data: [],
      },
      spirits: {
        data: [],
      },
      wines: {
        data: [],
      },
      "non-alcoholic": {
        data: [],
      },
      coffee: {
        data: [],
      },
    },
  },
  getters: {
    getItem: (state) => (type, category, id) => {
      return state[type][category]["data"].find((item) => item.id === +id);
    },
  },
  mutations: {
    setItem(state, payload) {
      state[payload.type][payload.category]["data"] = payload.data;
    },
    setBeerStyles(state, payload) {
      state.beer_styles = payload.data;
    },
    setBeerCategories(state, payload) {
      state.beer_categories = payload.data;
    },
    setBeerBreweries(state, payload) {
      state.beer_breweries = payload.data;
    },
  },
  actions: {
    async fetchFoods(context) {
      await fetch(process.env.VUE_APP_API_URL + "foods?posts_per_page=1000")
        .then((res) => res.json())
        .then((d) => {
          context.commit("setItem", {
            type: "food",
            category: "burgers",
            data: d.filter(
              (o) => o.acf.category === "burger" && o.acf.visible === true
            ),
          });
          context.commit("setItem", {
            type: "food",
            category: "sandwiches",
            data: d.filter(
              (o) => o.acf.category === "sandwich" && o.acf.visible === true
            ),
          });
          context.commit("setItem", {
            type: "food",
            category: "bruschette",
            data: d.filter(
              (o) => o.acf.category === "bruschetta" && o.acf.visible === true
            ),
          });
          context.commit("setItem", {
            type: "food",
            category: "snacks",
            data: d.filter(
              (o) => o.acf.category === "snack" && o.acf.visible === true
            ),
          });
          context.commit("setItem", {
            type: "food",
            category: "plates",
            data: d.filter(
              (o) => o.acf.category === "plate" && o.acf.visible === true
            ),
          });
        })
        .catch((err) => console.log(err));

      // REMOVE FOOD BASED ON TIME
      let date = new Date();
      let hour = date.getHours();
      if (hour >= 3 && hour < 18) {
        if (this.state.options.extra.data.hide_foods) {
          // Nascondere alcuni alimenti (poco personale)
          let food_categories = [
            "burgers",
            "sandwiches",
            "plates",
            "snacks",
            "bruschette",
          ];
          // Hide Foods
          this.state.options.extra.data.hidden_foods.forEach((h) => {
            food_categories.forEach((c) => {
              let index = this.state.food[c].data.findIndex((o) => o.id === h);
              if (index !== -1) {
                this.state.food[c].data[index].acf.visible = false;
              }
            });
          });
        }
      }
      if (
        hour === 22 ||
        hour === 23 ||
        hour === 0 ||
        hour === 1 ||
        hour === 2 ||
        hour === 3
      ) {
        // Visualizzare testo "Cucina chiusa"
        this.state.options.extra.data.too_late_for_food = true;
      }
    },
    async fetchBeers(context) {
      await fetch(process.env.VUE_APP_API_URL + "beers?posts_per_page=1000")
        .then((res) => res.json())
        .then((d) => {
          context.commit("setItem", {
            type: "drink",
            category: "beers",
            data: d.filter((o) => o.acf.visible === true),
          });
        })
        .catch((err) => console.log(err));
    },
    async fetchDrinks(context) {
      await fetch(process.env.VUE_APP_API_URL + "drinks?posts_per_page=1000")
        .then((res) => res.json())
        .then((d) => {
          context.commit("setItem", {
            type: "drink",
            category: "cocktails",
            data: d.filter(
              (o) =>
                o.acf.category.value === "cocktails" && o.acf.visible === true
            ),
          });
          context.commit("setItem", {
            type: "drink",
            category: "spirits",
            data: d.filter(
              (o) =>
                o.acf.category.value === "spirits" && o.acf.visible === true
            ),
          });
          context.commit("setItem", {
            type: "drink",
            category: "wines",
            data: d.filter(
              (o) => o.acf.category.value === "wines" && o.acf.visible === true
            ),
          });
          context.commit("setItem", {
            type: "drink",
            category: "non-alcoholic",
            data: d.filter(
              (o) =>
                o.acf.category.value === "non-alcoholic" &&
                o.acf.visible === true
            ),
          });
          context.commit("setItem", {
            type: "drink",
            category: "coffee",
            data: d.filter(
              (o) => o.acf.category.value === "coffee" && o.acf.visible === true
            ),
          });
        })
        .catch((err) => console.log(err));
    },
    async fetchOptions(context) {
      await fetch(process.env.VUE_APP_ACF_URL + "options/options")
        .then((res) => res.json())
        .then((d) => {
          context.commit("setItem", {
            type: "options",
            category: "extra",
            data: d.acf,
          });
        })
        .catch((err) => console.log(err));
    },
    setStylesCategoriesBreweries(context) {
      let beer_styles = [];
      let beer_categories = [];
      let beer_breweries = [];
      this.state.drink.beers.data.forEach((i) => {
        beer_styles.push(i.acf.style.label);
        beer_categories.push(i.acf.category.label);
        beer_breweries.push(i.acf.brewery.label);
      });
      const styles = new Set(beer_styles);
      const categories = new Set(beer_categories);
      const breweries = new Set(beer_breweries);

      // commit
      context.commit("setBeerStyles", {
        data: styles,
      });
      context.commit("setBeerCategories", {
        data: categories,
      });
      context.commit("setBeerBreweries", {
        data: breweries,
      });
    },
  },
  modules: {},
});
