export const messages = {
  it: {
    Tutti: "Tutti",
    Tutte: "Tutte",
    "Birre trovate":
      "Nessuna birra trovata | 1 birra trovata | {count} birre trovate",
    Filtri: "Filtri",
    Applica: "Applica",
    Reset: "Cancella filtri",
    "Ordina per": "Ordina per",
    Nome: "Nome",
    Prezzo: "Prezzo",
    Descrizione: "Descrizione",
    Disponibilita: "Disponibilità",
    Allergeni: "Allergeni",
    Extra: "Extra",
    Coperto: "Coperto",
    Attesa: "I tempi di attesa potrebbero allungarsi nei momenti più affollati",
    "Aggiunta di patatine": "Aggiunta di patatine",
    "Servizio esterno": "Servizio esterno su cibi e bevande",
    "Birra consigliata": "Birra consigliata",
    Spina: "Spina",
    Lattina: "Lattina",
    Bottiglia: "Bottiglia",
    varianti: "{count} varianti disponibili",
    "In Evidenza": "In Evidenza",
    "Nuovi arrivi": "Nuovi arrivi",
    categories: {
      Burgers: "Burgers",
      "Panini Caldi": "Panini Caldi",
      Piatti: "Piatti",
      Snacks: "Snacks",
      Bruschette: "Bruschette",
      Birre: "Birre",
      Cocktails: "Cocktails",
      Superalcolici: "Superalcolici",
      Vini: "Vini",
      Analcolici: "Analcolici",
      Caffetteria: "Caffetteria",
    },
    allergens: {
      Glutine: "Glutine",
      "Latte e derivati": "Latte e derivati",
      Soia: "Soia",
      Solfiti: "Solfiti",
      Uova: "Uova",
      Arachidi: "Arachidi",
      Crostacei: "Crostacei",
      "Frutta a guscio": "Frutta a guscio",
      "Pesce e molluschi": "Pesce e molluschi",
      Sedano: "Sedano",
      Sename: "Senape",
      Sesamo: "Sesamo",
    },
    beer_categories: {
      Ambrata: "Ambrata",
      Bianca: "Bianca",
      Bionda: "Bionda",
      Nera: "Nera",
      Rossa: "Rossa",
    },
    Stile: "Stile",
    Stili: "Stili",
    Categoria: "Categoria",
    Categorie: "Categorie",
    Colore: "Colore",
    "Vol.": "Vol.",
    Birrificio: "Birrificio",
    Birrifici: "Birrifici",
    Nazione: "Nazione",
  },
  en: {
    Tutti: "All",
    Tutte: "All",
    "Birre trovate": "No beers found | 1 beer found | {count} beers found",
    Filtri: "Filters",
    Applica: "Apply",
    Reset: "Reset All",
    "Ordina per": "Order by",
    Nome: "Name",
    Prezzo: "Price",
    Descrizione: "Description",
    Disponibilita: "Availability",
    Allergeni: "Allergens",
    Extra: "Extra",
    Coperto: "Cover charge",
    Attesa: "Waiting times may be longer at the busiest times",
    "Aggiunta di patatine": "Adding chips",
    "Servizio esterno": "Outside service",
    "Birra consigliata": "Recommended beer",
    Spina: "Tap",
    Lattina: "Can",
    Bottiglia: "Bottle",
    varianti: "{count} choices available",
    "In Evidenza": "Featured",
    "Nuovi arrivi": "New arrivals",
    categories: {
      Burgers: "Burgers",
      "Panini Caldi": "Sandwiches",
      Piatti: "Plates",
      Snacks: "Snacks",
      Bruschette: "Bruschette",
      Birre: "Beers",
      Cocktails: "Cocktails",
      Superalcolici: "Spirits",
      Vini: "Wines",
      Analcolici: "Soft drinks",
      Caffetteria: "Cafeteria",
    },
    allergens: {
      Glutine: "Gluten",
      "Latte e derivati": "Milk and derivatives",
      Soia: "Soy",
      Solfiti: "Sulfites",
      Uova: "Eggs",
      Arachidi: "Peanuts",
      Crostacei: "Crustaceans",
      "Frutta a guscio": "Nuts",
      "Pesce e molluschi": "Fish and sellfishes",
      Sedano: "Celery",
      Sename: "Mustard",
      Sesamo: "Sesame",
    },
    beer_categories: {
      Ambrata: "Amber",
      Bianca: "White",
      Bionda: "Blonde",
      Nera: "Black",
      Rossa: "Red",
    },
    Stile: "Style",
    Stili: "Styles",
    Categoria: "Category",
    Categorie: "Categories",
    Colore: "Color",
    "Vol.": "Vol.",
    Birrificio: "Brewery",
    Birrifici: "Breweries",
    Nazione: "Country",
  },
};
