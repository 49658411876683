<template>
  <div v-if="this.$store.state.isLoading">
    <Loading />
  </div>
  <div v-else>
    <div class="fixed top-0 left-0 sm:hidden opacity-30">
      <img class="w-full h-full" src="@/assets/img/home-bg.jpeg" />
    </div>
    <Navbar />
    <div class="relative pt-3 pb-8">
      <div class="container mb-6">
        <Transition name="fade-x" mode="out-in" appear>
          <div
            class="flex items-center space-x-4 text-3xl"
            v-if="this.$route.meta.type"
          >
            <div
              @click="
                this.$route.meta.type === 'drink'
                  ? this.$router.push({
                      name: 'foodCategory',
                      params: { category: 'burgers' },
                    })
                  : ''
              "
              class="font-bold"
              :class="[
                this.$route.meta.type === 'food'
                  ? 'text-gray-800'
                  : 'text-gray-300 cursor-pointer',
              ]"
            >
              Food
            </div>
            <p class="text-gray-300">/</p>
            <div
              @click="
                this.$route.meta.type === 'food'
                  ? this.$router.push({
                      name: 'drinkCategory',
                      params: { category: 'beers' },
                    })
                  : ''
              "
              class="font-bold"
              :class="[
                this.$route.meta.type === 'drink'
                  ? 'text-gray-800'
                  : 'text-gray-300 cursor-pointer',
              ]"
            >
              Drink
            </div>
          </div>
          <div v-else-if="item" class="flex text-3xl" :key="item.id">
            <p class="font-bold text-gray-800" v-html="item.title"></p>
          </div>
          <div v-else="$route.meta.title" class="flex text-3xl">
            <p class="font-bold text-gray-800" v-html="$route.meta.title"></p>
          </div>
        </Transition>
      </div>
      <router-view v-slot="{ Component }">
        <Transition name="fade" mode="out-in" appear>
          <component :is="Component" />
        </Transition>
      </router-view>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import Loading from "@/components/Loading";

export default {
  name: "App",
  components: { Navbar, Loading },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.$store.state.isLoading = true;
      await this.$store.dispatch("fetchOptions");
      await this.$store.dispatch("fetchFoods");
      await this.$store.dispatch("fetchBeers");
      await this.$store.dispatch("fetchDrinks");
      await this.$store.dispatch("setStylesCategoriesBreweries");
      this.$store.state.isLoading = false;
    },
  },
  computed: {
    item() {
      if (this.$route.name === "food" || this.$route.name === "drink") {
        return this.$store.getters.getItem(
          this.$route.name,
          this.$route.params.category,
          this.$route.params.id
        );
      }
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");
</style>
