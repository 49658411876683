<template>
  <button
    type="button"
    class="bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
    role="switch"
    aria-checked="false"
    @click="
      $i18n.locale === 'it' ? ($i18n.locale = 'en') : ($i18n.locale = 'it')
    "
  >
    <span
      :class="[
        $i18n.locale === 'it'
          ? 'opacity-0 ease-out duration-100'
          : 'opacity-100 ease-in duration-200',
      ]"
      class="text-[9px] font-semibold ease-out duration-100 absolute left-0 h-full w-full flex items-center justify-start ml-[5.5px] text-gray-400 transition-opacity"
    >
      IT
    </span>
    <span
      :class="[$i18n.locale === 'en' ? 'translate-x-5' : 'translate-x-0']"
      class="pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
    >
      <span
        :class="[
          $i18n.locale === 'en'
            ? 'opacity-0 ease-out duration-100'
            : 'opacity-100 ease-in duration-200',
        ]"
        class="text-[9px] font-semibold ease-in duration-200 absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
        aria-hidden="true"
      >
        IT
      </span>
      <span
        :class="[
          $i18n.locale === 'en'
            ? 'opacity-100 ease-in duration-200'
            : 'opacity-0 ease-out duration-100',
        ]"
        class="text-[9px] font-semibold ease-out duration-100 absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
        aria-hidden="true"
      >
        EN
      </span>
    </span>
    <span
      :class="[
        $i18n.locale === 'en'
          ? 'opacity-0 ease-out duration-100'
          : 'opacity-100 ease-in duration-200',
      ]"
      class="text-[9px] font-semibold ease-out duration-100 absolute right-0 h-full w-full flex items-center justify-end mr-[3.5px] text-gray-400 transition-opacity"
    >
      EN
    </span>
  </button>
</template>

<script>
export default {
  name: "ToggleLanguage",
};
</script>

<style scoped></style>
