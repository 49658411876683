<template>
  <div class="sticky bg-white/80 backdrop-blur-sm top-0 z-10 w-full">
    <div class="container py-5 flex items-center justify-between relative">
      <!-- back arrow -->
      <Transition name="fade" mode="out-in">
        <router-link
          :to="{
            path: this.$store.state.back,
          }"
          v-if="$route.params.id || this.$route.name === 'beer_curiosity'"
        >
          <div class="w-11 h-6 flex items-center">
            <svg
              class="fill-current text-gray-900 w-5 h-5"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M7 16l-4-4m0 0l4-4m-4 4h18"></path>
            </svg>
          </div>
        </router-link>
        <div
          v-else-if="
            this.$store.state.options.extra.data.show_lucky_game_banner
          "
          class="w-11 h-6"
        >
          <a href="https://lucky.ilgalloneropub.com" target="_blank">
            <img src="@/assets/img/lucky-icon.png" class="w-6 h-6 rotate-12" />
          </a>
        </div>
        <div v-else class="w-11 h-6"></div>
      </Transition>
      <!-- logo -->
      <div @click="goToHome" class="relative w-6 h-6">
        <img src="@/assets/logo.svg" alt="" />
      </div>
      <!-- language -->
      <ToggleLanguage />
    </div>
  </div>
</template>

<script>
import ToggleLanguage from "@/components/ToggleLanguage";

export default {
  name: "Navbar",
  components: {
    ToggleLanguage,
  },
  methods: {
    goToHome() {
      this.$store.state.lastPos = 0;
      this.$router.push("/");
      this.$nextTick(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      });
    },
  },
};
</script>
