import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";

const routes = [
  {
    path: "/",
    redirect: {
      path: "/food/burgers",
    },
  },
  // Food Routes
  {
    path: "/food",
    name: "foods",
    meta: {
      type: "food",
    },
    component: () => import("@/views/food/Food"),
    children: [
      {
        path: ":category",
        name: "foodCategory",
        component: () => import("@/views/food/Category"),
      },
    ],
  },
  {
    path: "/food/:category/:id(\\d+)",
    name: "food",
    component: () => import("@/views/food/Single"),
  },
  // Drink Routes
  {
    path: "/drink",
    name: "drinks",
    meta: {
      type: "drink",
    },
    component: () => import("@/views/drink/Drink.vue"),
    children: [
      {
        path: "/drink/:category",
        name: "drinkCategory",
        component: () => import("@/views/drink/Category.vue"),
      },
    ],
  },
  {
    path: "/drink/:category/:id(\\d+)",
    name: "drink",
    component: () => import("@/views/drink/Single.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.afterEach((to, from) => {
  if (
    from.name === undefined &&
    (router.currentRoute.value.name === "food" ||
      router.currentRoute.value.name === "drink")
  ) {
    store.state.back = `/${router.currentRoute.value.name}/${router.currentRoute.value.params.category}`;
  } else if (
    router.currentRoute.value.name === "drink" &&
    from.name === "food"
  ) {
    store.state.back = from.path;
  } else if (
    router.currentRoute.value.name === "food" &&
    from.name === "drink"
  ) {
    store.state.back = `/food/${router.currentRoute.value.params.category}`;
  } else {
    store.state.back = from.path;
  }
});

export default router;
