import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import App from "./App.vue";
import "./assets/index.css";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import AOS from "aos";
import "aos/dist/aos.css";
import { messages } from "@/locales/locale.js";
import { func } from "./functions";

const i18n = createI18n({
  locale: "it",
  fallbackLocale: "it",
  messages,
});

const app = createApp(App);

app.aos = AOS.init();

app.config.globalProperties.$func = func;
app.use(i18n);
app.use(AOS);
app.use(store);
app.use(router);
app.mixin({
  methods: {
    money: (price) => {
      return new Intl.NumberFormat("it-IT", {
        currency: "EUR",
        style: "currency",
      }).format(price);
    },
  },
});
app.mount("#app");

// Disabilito pinch-to-zoom
document.addEventListener("gesturestart", function (e) {
  e.preventDefault();
});

// Rimuovo filtri in localstorage quando esco
window.onbeforeunload = localStorage.removeItem("filtersApplied");
