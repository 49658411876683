<template>
  <div class="flex h-screen items-center justify-center py-6">
    <div class="flex flex-col items-center space-y-7 animate-pulse">
      <img src="@/assets/logo.svg" class="w-24 h-24" />
      <span class="font-medium uppercase text-xs">Caricamento...</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loading",
};
</script>
